@import '_variables';

.Dialog {
  position: absolute;
  top: $header-height;
  bottom: calc(-100% - $header-height);
  left: 0;
  z-index: 3;
  background-color: rgba(71, 77, 102, 0.4);
  overflow: hidden;
  width: 0;

  &--visible {
    width: 100%;
  }

  &__header {
    height: $header-height;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .Button--text {
      color: $base-green;

      svg {
        margin-right: 5px;
        margin-bottom: -1px;
      }
    }
  }

  &__close-btn {
    @media screen and (max-width: $breakpoint-tablet) {
      display: none;
    }
  }

  &__close-mobile {
    @media screen and (min-width: $breakpoint-tablet) {
      display: none;
    }

    svg {
      width: 15px;
      height: 15px;
    }
  }

  &__title {
    display: flex;
    align-items: center;

    svg {
      stroke: $base-green;
      transform: rotate(180deg);
    }
  }

  &__content {
    background-color: $white;
    margin-right: -450px;
    width: 400px;
    height: 100%;
    z-index: 4;
    right: 0;
    top: 0;
    padding: 0 20px;
    position: fixed;
    box-shadow: 0px 12px 40px rgba(16, 24, 64, 0.12);
    transition: margin-right 0.3s ease-in-out;
    overflow-y: scroll;

    @media screen and (max-width: $breakpoint-tablet) {
      width: 90%;
      margin-right: -100%;
      box-shadow: none;
      top: $header-height;
    }

    &--visible {
      margin-right: 0;
    }
  }
}
