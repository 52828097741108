@import '_variables.scss';

.CustomSelect {
  &__label {
    display: block;
    margin-bottom: 5px;
    font-size: $font-14;
    color: $dark-2;
  }

  &__select {
    > div {
      border-radius: 10px;
      border: 1px solid $grey-5;

      > div:last-child {
        > span {
          display: none;
        }
      }
    }
  }

  &__value-container {
    padding: $font-8 0;
    border-radius: 10px;
    background-position: right 2% bottom 50%;
    background: $white url(/assets/Caret.svg) no-repeat 100% 54%;
  }

  &__dropDown {
    > svg {
      width: 14px;
    }
  }
}

.css-1s2u09g-control {
  border-radius: 10px;
  border: 1px solid $grey-5;
}
