@import '_variables';

.CustomTable {
  border-collapse: collapse;
  width: 100%;

  &__header {
    th {
      text-transform: uppercase;
      background-color: $neutral-4;
      padding: 1.05rem;
      text-align: left;
      color: $grey-2;
      font-size: $font-12;
      font-weight: 600;
      white-space: normal;
    }
  }

  &__body {
    tr {
      border-top: 1px solid $neutral-7;
      border-bottom: 1px solid $neutral-7;
      cursor: pointer;

      &:nth-child(even) {
        background-color: $white;
      }

      &:nth-child(odd) {
        background-color: $light-teal-3;
      }

      td {
        padding: 1.05rem;
        font-size: $font-12;
        color: $grey-2;
        text-align: left;
        white-space: nowrap;
      }
    }
  }

  &__footer {
    padding: 1.5rem 1.05rem;
  }

  .Button {
    border-radius: 10px;
  }
}

.Pagination {
  display: flex;
  align-items: center;
  color: $dark-5;
  font-size: $font-12;
  font-weight: 600;

  &__icons {
    margin-left: auto;
  }

  @media screen and (max-width: $breakpoint-tablet) {
    justify-content: space-between;

    &__icons {
      margin-left: 0;
    }
  }
}
