@import '_variables';

$sidebar-width: 250px;
$header-height: 87px;

// Layout
.Layout {
  min-height: 100vh;

  &__wrapper {
    display: flex;
  }

  &__content {
    margin-top: $header-height;
    margin-left: $sidebar-width;
    background-color: $white;
    width: 100%;
    height: calc(100vh - $header-height);

    @media screen and (max-width: $breakpoint-tablet) {
      margin-left: 0;
      overflow-x: hidden;
    }
  }
}

// Side Navigation
.SideNav {
  background-color: $white;
  transition: width ease-in 1s;

  &--open {
    @media screen and (min-width: $breakpoint-tablet) {
      display: block;
    }
  }

  &--collapsed {
    display: block;

    @media screen and (max-width: $breakpoint-tablet) {
      display: none;
    }
  }

  &__backdrop {
    background-color: black;
    opacity: 0.5;
    position: fixed;
    background: black;
    width: 100%;
    top: 0;
    bottom: 0;
    z-index: 1;

    @media screen and (min-width: $breakpoint-tablet) {
      display: none;
    }
  }

  &__logo {
    padding: 30px 0 0 30px;
    margin-bottom: 30px;
  }

  &__wrapper {
    position: fixed;
    z-index: 3;
    top: 0;
    bottom: 0;
    width: $sidebar-width;
    background-color: $white;
    box-shadow: 2px -2px 4px 4px rgba(16, 24, 64, 0.02);
  }

  &__nav-items {
    padding: 0;
  }

  &__item {
    a {
      display: flex;
      align-items: center;
      color: $grey-2;
      text-decoration: none;
      padding: 20px 0 20px 30px;
    }

    svg {
      margin-right: 20px;
      fill: $neutral-6;
    }

    &--active {
      background-color: $success-light-green;
      color: $success-green;

      svg {
        fill: $base-green;
      }
    }
  }
}

.Header {
  position: fixed;
  z-index: 2;
  left: $sidebar-width;
  right: 0;
  background-color: $light-teal-3;
  border-bottom: 1px solid $grey-1;

  @media screen and (max-width: $breakpoint-tablet) {
    left: 0;
    right: 0;
    background-color: $neutral-1;
  }

  &__content {
    display: flex;
    align-items: center;
    flex-grow: 1;

    @media screen and (max-width: $breakpoint-tablet) {
      h1 {
        font-size: $font-16;
      }
    }
  }

  &__wrapper {
    height: $header-height;
    display: flex;
    align-items: center;
    padding: 0px 20px;
    box-shadow: 2px -2px 4px rgba(16, 24, 64, 0.02);

    @media screen and (max-width: $breakpoint-tablet) {
      padding: 0px 10px;
    }
  }

  &__menu {
    display: none;
    margin-right: 15px;

    @media (max-width: $breakpoint-tablet) {
      display: block;
    }
  }
}

.HeaderComponents {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.Url {
  display: flex;
  align-items: center;
  border: 1px solid $neutral-7;
  border-radius: 10px;

  @media screen and (max-width: $breakpoint-tablet) {
    border: none;
    border-radius: 50%;
    height: 32px;
    width: 32px;
    background-color: $white;

    svg {
      width: 22px;
      stroke-width: 5px;
    }
  }

  &__icon {
    border-left: 1px solid $neutral-7;

    @media screen and (max-width: $breakpoint-tablet) {
      border: none;
    }

    svg {
      stroke: $base-green;
    }

    &--copy {
      @media screen and (max-width: $breakpoint-tablet) {
        display: none;
      }
    }
  }

  &__url {
    padding: 8px 16px;

    @media screen and (max-width: $breakpoint-tablet) {
      display: none;
    }
  }
}

.Notification {
  position: relative;
  margin: 0 30px;

  @media screen and (max-width: $breakpoint-tablet) {
    margin: 0 10px;
  }

  span {
    position: absolute;
    top: -2px;
    right: 3px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    font-size: $font-11;
    text-align: center;
    color: $white;
    font-weight: 600;
    background-color: $error-red;
  }
}

.AvatarDropdown {
  position: relative;

  &__wrapper {
    display: flex;
    align-items: center;
    background: unset;
    border: unset;
    cursor: pointer;

    @media screen and (max-width: $breakpoint-tablet) {
      .Paragraph {
        display: none;
      }
    }
  }

  &__avatar {
    margin-right: 10px;
  }

  &__content {
    position: absolute;
    top: 0;
    right: 0;
    background-color: $white;
    border-radius: 16px;
    box-shadow: -2px 4px 4px rgba(16, 24, 64, 0.02);
  }

  &__username {
    border-bottom: 1px solid $neutral-7;
    padding: 20px 12px;
    display: flex;
    align-items: center;
    white-space: nowrap;
  }

  &__nav {
    padding: 0 0 10px;
    margin: 0;

    li {
      list-style: none;
      padding: 8px 13px;
      display: flex;
      align-items: center;
      color: $neutral-6;
      cursor: pointer;

      svg {
        margin-right: 10px;
        width: 30px;
        stroke: #8f95b2;
      }

      &:hover {
        color: $base-green;

        svg {
          stroke: $base-green;
        }
      }
    }
  }
}

.Tooltip {
  position: relative;
  display: inline-block;

  &:hover {
    .Tooltip__content {
      opacity: 1;
      visibility: visible;
    }
  }

  &__content {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    color: $white;
    border-radius: 10px;
    padding: 8px 16px;
    background-color: $dark-5;
    white-space: nowrap;
    transition: opacity ease-in 0.2s;
  }

  &__content::after {
    content: ' ';
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent $dark-5 transparent;
  }
}
