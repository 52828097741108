@import '../../../variables';

.Layout {
  background-color: $white;

  @media (min-width: 376px) {
    background-color: $neutral-1;
  }
}

.Auth {
  margin: 2.5rem auto;
  max-width: 31.25rem;

  &__container {
    background-color: $white;
    padding: 2.25rem 0;
    margin: 0 $font-24;

    @media (min-width: 376px) {
      box-shadow: 2px -2px 4px rgba(16, 24, 64, 0.02),
        -2px 4px 4px rgba(16, 24, 64, 0.02);
      border-radius: 16px;
      overflow: hidden;
    }

    @media (min-width: 400px) {
      padding: 2.25rem 1rem;
    }

    @media (min-width: 768px) {
      padding: 2.25rem 2rem;
    }
  }
  &__email {
    font-size: 0.875rem;
    color: $grey-2;
    text-align: center;
    align-items: center;
    position: relative;
    display: flex;
    justify-content: center;
  }
  &__email::after,
  &__email::before {
    content: '';
    position: absolute;
    width: 28%;
    border: 0.5px solid $grey-3;
  }
  &__email::after {
    left: 0;
  }
  &__email::before {
    right: 0;
  }
  &__emailDone {
    margin: 0.125rem;
  }
  &__title {
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
    white-space: nowrap;
    margin: 0;
    color: $font-base;

    @media (min-width: 376px) {
      font-size: 1.6rem;
    }
  }

  &__google {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0.5rem;
    margin: 2rem 0;
    border-radius: 10px;
    cursor: pointer;
    border: 1px solid $grey-1;
    background-color: transparent;

    p.Auth__continue {
      margin-left: 0.75rem;
    }
  }

  &__indicatorWrapper {
    display: flex;
    align-items: center;
    margin: 0.75rem 0;
  }

  &__weak {
    color: $base-red;
  }

  &__okay {
    color: $base-orange;
  }

  &__strong {
    color: $base-green;
  }

  &__indicatorStatus {
    font-size: 0.75rem;
    white-space: nowrap;
    margin-right: 0.5rem;
  }

  &__strength-indicator {
    display: flex;
    grid-column-gap: 1px;
    div[class^='Indicator'] {
      width: 22.93px;
      height: 5px;
    }
  }

  &__validatorList {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    ul {
      font-size: 0.75rem;
      margin: 0;
      padding: 0;
      li {
        margin: 0.2rem 1.5rem;
        color: $grey-2;
      }
    }
  }

  &__valid {
    list-style-image: url('../../../assets/tick.svg');
    margin: 0;
  }
  &__invalid {
    list-style-image: url('../../../assets/cross.svg');
    margin: 0;
  }

  &__term {
    font-size: 0.75rem;
    text-align: center;
    margin-top: 3rem;
    margin-bottom: 1.5rem;
    &-highlight {
      color: $base-green;
    }
  }

  &__link {
    font-weight: 500;
    margin-bottom: 30px;
  }

  &__field {
    margin-top: 20px;

    > div:last-child {
      border-radius: 10px;
      align-items: center;

      > span[icon='eye-off'] {
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: url('../../../assets/eye_close.svg') 0 0 no-repeat;

        > svg {
          display: none;
        }
      }

      > span[icon='eye-open'] {
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: url('../../../assets/eye_open.svg') 0 0 no-repeat;

        > svg {
          display: none;
        }
      }
    }
  }

  &__btn {
    width: 100%;
    border-radius: 10px;
  }

  &__btnContained {
    display: flex;
    align-items: center;
    border-radius: 10px;
    svg {
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }
  }

  &__btn.inbox {
    width: max-content;
    margin-top: 1.625rem;
    margin-bottom: 1.1875rem;
    padding-left: 3.6875rem;
    padding-right: 3.6875rem;
    border-radius: 10px;
  }

  &__btn.login {
    margin: 1.5rem 0;
  }

  &__footNote {
    font-size: 0.875rem;
    text-align: center;
    margin-top: 1.5rem;
    &-highlight {
      color: $base-green;
      text-decoration: none;
      cursor: pointer;
    }

    &-m {
      text-align: center;
    }
  }

  &__inboxHeading {
    margin-bottom: 1.5rem;
    &.code {
      margin-top: 3rem;
    }
  }

  &__checkedCircle {
    margin-top: 6.375rem;
    margin-bottom: 4.875rem;
  }

  &__profileSet {
    font-size: 2rem;
    line-height: 40px;
    margin-top: 4.4375rem;
    margin-bottom: 2rem;
    svg {
      width: 111px;
      height: 111px;
    }
  }

  &__envelope {
    width: 100.75px;
    margin-top: 5.390625rem;
    margin-bottom: 3.453125rem;
    svg {
      width: 100%;
    }
  }

  &__back {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  &__backText {
    margin-left: 0.5625rem;
    color: $base-green;
  }

  &__inboxContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    svg {
      width: 112px;
      height: 112px;
    }
  }

  &__centerText {
    font-size: 0.875rem;
  }

  /**
      Reset password screen styles
  */
  &__resetNote {
    text-align: center;
    margin-top: 0.5rem;
    margin-bottom: 1.703125 rem;
  }

  &__numberVerfiy {
    width: 100% !important;
    margin-top: 1.5rem;
    margin-bottom: 4rem;
    > div {
      > input[type='tel'] {
        width: 48px !important;
        height: 56px !important;
        border: solid 1px $light-green-3;
        border-radius: 8px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 48px;
        line-height: 60px;
        color: $base-green;
      }
      > input[type='tel']:focus {
        border-width: 2px;
      }
      > input + input {
        margin-left: 0.675rem;
      }
    }
  }

  &__verifyError {
    margin-top: $font-6;
  }

  &__verifyHeading {
    font-size: $font-32;
  }
}

.Layout {
  &__PoweredBy {
    display: flex;
    justify-content: center;
    padding-bottom: 7.5rem;
  }

  &__Simpa {
    display: flex;
    justify-content: center;
    background-color: $neutral-1;
    padding: 1.56375rem 0px 1.0325rem 0px;

    @media (min-width: 769px) {
      justify-content: flex-start;
      padding: 2.0625rem 0px 1.0325rem 4.1875rem;
    }
  }
}

.SignUpDone {
  &__PoweredBy {
    display: flex;
    justify-content: center;
    padding-bottom: 7.5rem;
  }

  &__Simpa {
    display: none;
    @media (min-width: 376px) {
      display: flex;
      justify-content: center;
      background-color: $neutral-1;
      padding: 1.56375rem 0px 1.0325rem 0px;
    }
    @media (min-width: 769px) {
      justify-content: flex-start;
      padding: 2.0625rem 0px 1.0325rem 4.1875rem;
    }
  }
}
