@import '../../../variables';

.Auth {

    &__alert {
        display: flex;
        align-items: flex-start;
        background-color: $error-bg-red;
        padding: 0.75rem 1rem;
        margin-top: 2rem;
        margin-bottom: 1.5rem;
        border: 1px solid $error-red;
        border-radius: 16px;

        svg {
            flex-shrink: 0;
        }
    }

    &__alert-success {
        display: flex;
        align-items: flex-start;
        background-color: $success-light-green;
        padding: 0.75rem 1rem;
        margin-top: 2rem;
        margin-bottom: 1.5rem;
        border: 1px solid $success-green;
        border-radius: 16px;
        svg {
            flex-shrink: 0;
            width: 16px;
            height: 16px;
        }
    }

    &__alertText {
        margin-left: 1rem;
        color: $text-error-red;
        text-align: left;
    }
    &__alertText-success {
        margin-left: 1rem;
        color: $success-default-green;
        text-align: left;
    }

    &__footNoteSignin {
        text-align: center;
        font-size: 0.875rem;
    }

    &__term-highlight {
        text-decoration: none;
        line-height: 24px;
        cursor: pointer;
    }

}