$light-red-1: #fef6f6;
$light-red-2: #fae2e2;
$base-red: #ec4c47;
$dark-red: #bf0e08;
$error-red: #d14343;
$text-error-red: #a73636;
$dark-text-red: #7d2828;
$error-bg-red: #fdf4f4;
$error-bg: #f9dada;

$light-orange-1: #fdf8f3;
$light-orange-2: #fae3cd;
$base-orange: #d9822b;
$dark-orange: #95591e;

$light-yellow-1: #fef8e7;
$light-yellow-2: #fbe6a2;
$base-yellow: #f7d154;
$dark-yellow: #7e6514;
$dark-yellow-2: #ffb020;

$light-green-1: #f1faf5;
$light-green-2: #d4eee2;
$light-green-3: #dcf2ea;
$light-green-4: #edf0f2b5;
$base-green: #47b881;
$accent-green: #eef8f4;
$dark-green: #00783e;
$hover-green: #339a73;
$success-green: #52bd94;
$success-light-green: #f5fbf8;
$success-default-green: #317159;
$text-green-1: #429777;
$text-green-2: #47b881;
$focus-green: #a3e6cd;

$light-teal-1: #f1fbfc;
$light-teal-2: #d2eef3;
$light-teal-3: #fafbff;
$base-teal: #14b5d0;
$dark-teal: #007489;

$light-blue-1: #f6f9fd;
$light-blue-2: #dbeaf7;
$base-blue: #1070ca;
$dark-blue: #084b8a;

$light-purple-1: #f8f7fc;
$light-purple-2: #eae7f8;
$base-purple: #7540f6;
$dark-purple: #3608aa;

$white: #ffffff;
$neutral-1: #f5f8f9;
$neutral-2: #edf0f2;
$neutral-3: #e4e7eb;
$neutral-4: #edeff5;
$neutral-5: #f4f6fa;
$neutral-6: #8f95b2;
$neutral-7: #e6e8f0;
$neutral-8: #f9fafc;

$dark-1: #66788a;
$dark-2: #425a70;
$dark-3: #234361;
$dark-4: #435a6f;
$dark-5: #696f8c;
$dark-6: #474d66;

$light-1: #d7dae5;

$grey-1: #d8dae5;
$grey-2: #474d66;
$grey-3: #e6e8e0;
$grey-4: #a8adb7;
$grey-5: #e4e7ec;
$grey-6: #eeeff5;
$grey-7: #00000080;
$grey-8: #e6e8f0;
$focus-grey: #d6e0ff;

$font-base: #101840;

/**
    FONT SIZE
*/

$font-6: 0.5rem;
$font-7: 0.5rem;
$font-8: 0.5rem;
$font-9: 0.5625rem;
$font-10: 0.625rem;
$font-11: 0.6875rem;
$font-12: 0.75rem;
$font-13: 0.8125rem;
$font-14: 0.875rem;
$font-15: 0.9375rem;
$font-16: 1rem;
$font-17: 1.0625rem;
$font-18: 1.125rem;
$font-19: 1.1875rem;
$font-20: 1.25rem;
$font-21: 1.3125rem;
$font-22: 1.375rem;
$font-23: 1.4375rem;
$font-24: 1.5rem;
$font-25: 1.5625rem;
$font-26: 1.625rem;
$font-27: 1.6875rem;
$font-28: 1.75rem;
$font-29: 1.8125rem;
$font-30: 1.875rem;
$font-31: 1.9375rem;
$font-32: 2rem;

// Media breakpoints
$breakpoint-mobile: 320px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;

// utils
$header-height: 87px;

// shadows
$shadow-1: 2px 4px 10px rgba(16, 24, 64, 0.1);
