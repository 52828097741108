@import './variables';

body {
  margin: 0;
  font-family: 'Inter', 'Roboto', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input:disabled {
  cursor: not-allowed;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input[type='number'] {
  -moz-appearance: textfield;
  appearance: none;
}

button.Button--secondary:hover {
  background-color: $hover-green;
  transition: background-color 0.3s ease-in-out;
}

.skeleton {
  opacity: 0.7;
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 80%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

.flex {
  display: flex;
  align-items: center;
}

.flex-justify-between {
  justify-content: space-between;
}

button.button-global {
  display: flex;
  align-items: center;
  border-radius: 10px;
}

.global-select {
  > div {
    border-radius: 10px;
    border: 1px solid $grey-5;

    > div:first-child {
      padding: $font-8 0;
      border-radius: 10px;
      background-position: right 2% bottom 50%;
      background: $white url(./assets/Caret.svg) no-repeat 100% 54%;

      > div {
        padding-left: 0.6rem;
      }
    }

    > div:last-child {
      > span {
        display: none;
      }
      svg {
        display: none;
      }
    }
  }
}
