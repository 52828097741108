@import '_variables';

.CustomTooltip {
  position: relative;
  display: inline-block;
  z-index: 1;
  cursor: pointer;

  &:hover {
    .CustomTooltip__content {
      opacity: 1;
      visibility: visible;
    }
  }

  &__content {
    opacity: 0;
    position: absolute;
    visibility: hidden;
    color: $white;
    border-radius: 20px;
    padding: 14px 16px;
    margin-left: -60px;
    background-color: $dark-5;
    min-width: 270px;
    transition: opacity ease-in 0.2s;

    @media screen and (max-width: $breakpoint-tablet) {
      display: none;
    }

    &::after {
      content: ' ';
      position: absolute;
      margin-left: -10px;
      border-width: 10px;
      border-style: solid;
    }

    &--bottom {
      top: 125%;
    }

    &--top {
      bottom: 125%;
    }

    &--bottom::after {
      bottom: 100%;
      left: 47px;
      border-color: transparent transparent $dark-5 transparent;
    }

    &--top::after {
      top: 100%;
      left: 47px;
      border-color: $dark-5 transparent transparent transparent;
    }
  }
}
