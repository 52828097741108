@import '_variables';

.StepIcon {
  background-color: $light-green-3;
  height: 16px;
  width: 16px;
  display: flex;
  justify-content: center;
  border-radius: 4px;
  font-size: 0.625rem;
  font-weight: 600;

  &--active {
    background-color: $light-green-3;
  }

  &--process {
    color: $dark-green;
    background-color: $light-green-3;
  }

  &--wait {
    color: $dark-5;
    font-weight: 600;
    background-color: $neutral-4;
  }

  &--finish {
    color: $dark-5;
  }
}

.rc-steps {
  margin-bottom: 20px;
  font-family: 'Inter', sans-serif;
  font-size: 0.875rem;
}

.rc-steps-item-content .rc-steps-item-title {
  font-size: 0.875rem;
  font-weight: 600;
  color: $dark-5;
}

.rc-steps-item-wait .rc-steps-item-title {
  color: $dark-5;
}

.rc-steps-vertical .rc-steps-item-tail {
  left: 7px !important;
  padding: 16px 0 0px 0 !important;
}

.rc-steps-item-icon {
  width: 16px !important;
  height: 16px !important;
  line-height: 16px !important;
  text-align: center;
  border: none !important;
  font-size: 16px;
  margin-right: 8px;
  transition: background-color 0.3s, border-color 0.3s;
}

.rc-steps-item-wait .rc-steps-item-icon {
  width: 16px;
  height: 16px;
  text-align: center;
  border-radius: unset;
  font-size: 16px;
  margin-right: 8px;
}

.rc-steps-vertical .rc-steps-item-title {
  line-height: 10px !important;
}

.rc-steps-item-active .rc-steps-item-title {
  opacity: 1;
  color: $success-green !important;
}

.rc-steps-item-finish .rc-steps-item-tail:after {
  opacity: 1 !important;
  background-color: $base-green !important;
}

.rc-steps-item-finish .rc-steps-item-title {
  opacity: 1;
  color: $dark-5 !important;
}

.rc-steps-dot .rc-steps-item-tail:after {
  height: 2px;
  margin-top: 3px;
}

.rc-steps-item-process .rc-steps-item-tail:after,
.rc-steps-item-wait .rc-steps-item-tail:after {
  background: rgba($dark-1, 0.4) !important;
}
