@import '_variables';

.Profile {
  &__description {
    margin-bottom: 40px;
  }

  &__title {
    margin-bottom: 10px;
    text-align: center;
  }

  &__descriptionText {
    line-height: 24px;
  }

  &__progressContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
  }

  &__progress {
    text-align: center;
    padding: 0.5rem 1rem;
    background-color: $light-green-3;
    color: $success-default-green;
    font-size: 0.75rem;

    margin: 0 auto;
    line-height: 16px;
    border-radius: 10px;
  }

  &__field {
    margin-bottom: 20px;
    position: relative;

    &.Select {
      // I will get back to this to make the drop icon show
      padding: 11.5px 12px;
      background: #ffffff url('../../../assets/Caret.svg') no-repeat center
        right;
      background-position: right 2% bottom 50%;
      appearance: none;
      margin-bottom: unset;
    }

    &.Select::after {
      content: '';
      background: transparent url('../../../assets/Caret.svg') no-repeat 100%
        center;
    }
  }

  &__label {
    color: $dark-2;
    font-size: 0.875rem;
    margin-bottom: 5px;
  }

  &__logo-input {
    opacity: 0;
  }

  &__file-upload {
    width: 200px;
    border-radius: 10px;
    padding: 30px;
    margin-top: 5px;
    margin-bottom: 30px;
    cursor: pointer;
    text-align: center;
    border: 1px dashed rgba(16, 24, 64, 0.2);
    background-color: rgba(245, 248, 249, 0.8);
  }

  &__file-preview {
    margin-top: 5px;
    margin-bottom: 20px;
    img {
      width: 82px;
      height: 82px;
    }
  }

  &__actionsContainer {
    display: flex;
    margin-top: 5rem;
    align-items: center;
    justify-content: space-between;

    > button:first-child {
      display: none;

      @media (min-width: 768px) {
        display: unset;
        white-space: nowrap;
      }
    }
  }

  &__actions {
    width: 100%;
    display: flex;
    justify-content: space-between;

    button:last-child {
      margin-left: 15px;
    }

    button {
      width: 100%;
      display: flex;
      justify-content: center;
    }
    @media (min-width: 768px) {
      justify-content: flex-end;

      button {
        width: unset;
      }
    }

    &.spacer {
      justify-content: space-between;

      button:first-child {
        margin-left: 0px;

        svg {
          margin-left: 0px;
          margin-right: 0.5rem;
        }
      }
    }
  }

  &__inputSideBySide {
    display: flex;
    flex-wrap: wrap;

    > div {
      flex-grow: 1;

      &:first-child {
        @media (min-width: 768px) {
          margin-right: 0.625rem;
        }
      }
    }
  }

  &__inputSideBySideCode {
    display: flex;

    > div {
      &:first-child {
        width: 20%;

        > select {
          background-color: #f5fbf8;
          border-right: none;
          border-top-right-radius: 0px;
          border-bottom-right-radius: 0px;
        }
      }

      &:last-child {
        flex-grow: 1;
        > .TextField__container,
        .TextField__container--error {
          border-top-left-radius: 0px;
          border-bottom-left-radius: 0px;
          padding: 10px 12px;
        }
        > div:last-child {
          border-top-left-radius: 0px;
          border-bottom-left-radius: 0px;
          padding: 10px 12px;
        }
      }
    }
  }

  &__inputSideBySideCode-label {
    color: $grey-2;
    font-size: 0.9125rem;
  }
}

.Container {
  background-color: $white;
  border-radius: 10px;
}

@media (min-width: 376px) {
  .Container {
    padding: 40px 30px;
  }
}

.TextField {
  &__container {
    border-radius: 10px;
  }

  &__container:focus-within {
    border: 1px solid $focus-green;
    box-shadow: 0px 0px 0px 2px $focus-grey;
  }
}

.SelectField {
  margin-bottom: 20px;
  > select {
    border-radius: 10px;
    outline: none;
  }

  &__input:focus-within {
    border: 1px solid $focus-green;
    box-shadow: 0px 0px 0px 2px $focus-grey;
  }
}

.Radio {
  display: flex;
  margin: 1.625rem 0;

  &__error-feedback {
    color: $error-red;
  }

  &__left,
  &__right {
    display: flex;
    align-items: center;
    padding-top: 0.1rem;
  }

  &__left {
    margin-right: 1.875rem;
  }

  &__label {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
  }

  &__customInput {
    position: absolute;
    top: 3px;
    left: 0.4px;
    background-color: $grey-1;

    display: block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
  }

  &__customInput::after {
    content: '';
    position: absolute;
    top: 1px;
    left: 1px;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: white;
  }

  &__input {
    opacity: 0;
    margin-top: 0;
    margin-left: 0;
  }

  &__input:checked ~ &__customInput {
    background-color: $base-green;
  }

  &__input:checked ~ &__customInput::after {
    top: 4px;
    left: 4px;
    width: 8px;
    height: 8px;
  }

  &__text {
    margin-left: 0.5rem;
    margin-top: 2px;
  }
}

.Auth__container {
  &--resendCode {
    color: $base-green;
    cursor: pointer;
  }
}
// Loader screen
.VerifiedUserCheck {
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;

  &__logo {
    svg:last-child {
      margin-left: $font-10;
    }
  }

  &__contentLogo {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  &__note {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__noteText {
    font-weight: 600;
    line-height: 32px;
    font-size: $font-24;
    margin-top: 1.3125rem;
    text-align: center;
  }

  &__powerBy {
    margin-bottom: 2.625rem;

    @media (min-width: 768px) {
      margin-bottom: 5.4375rem;
    }
  }
}
