@import '_variables';

.IconButton {
  background-color: transparent;
  border: none;
  font-size: 16px;
  cursor: pointer;

  &:disabled {
    cursor: text;
    opacity: 0.5;
  }

  svg {
    color: $dark-5;
  }
}
