@import '_variables';

.Spinner {
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
height: 100vh;

  svg {
    width: 3.75em;
    margin-bottom: 50px;
    display: inline-block;
    vertical-align: middle;
    transform-origin: center;
    animation: rotate 2s linear infinite;

    circle {
      fill: none;
      stroke: $base-green;
      stroke-width: 3;
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
      stroke-linecap: round;
      animation: dash 1.5s ease-in-out infinite;
    }
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dashoffset: -125px;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
