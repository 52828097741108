@import '../../../variables';

.Toggle {
  &__switchContainer {
    display: flex;
    align-items: center;
  }

  &__switch {
    width: 28px;
    height: 16px;
    position: relative;
    display: inline-block;
  }

  &__switch input {
    width: 0;
    height: 0;
    opacity: 0;
  }

  &__slider {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    transition: 0.4s;
    position: absolute;
    background-color: #ccc;
    -webkit-transition: 0.4s;
  }

  &__slider:before {
    left: 4px;
    bottom: 2px;
    content: '';
    width: 12px;
    height: 12px;
    transition: 0.4s;
    position: absolute;
    background-color: white;
    -webkit-transition: 0.4s;
  }

  input:checked + &__slider {
    background-color: $base-green;
  }

  input:focus + &__slider {
    box-shadow: 0 0 1px $base-green;
  }

  input:checked + &__slider:before {
    transform: translateX(9px);
    -ms-transform: translateX(9px);
    -webkit-transform: translateX(9px);
  }

  /* Rounded sliders */
  &__slider-round {
    border-radius: 34px;
  }

  &__slider-round:before {
    border-radius: 50%;
  }

  &__statusText {
    color: $neutral-6;
    font-size: $font-12;
    margin-left: $font-6;
  }
}
